import styled from "styled-components"

export const Dot = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: calc(100% / ${props => props.number});
    height: 100%;

    &::after {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: #666;
        display: inline-block;
    }
`

export const SliderLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: black;
    height: 100%;
    width: calc((100% / ${props => props.number}) * ((${props => props.to} - ${props => props.min}) - ((${props => props.from} - ${props => props.min} - 1))));
    left: calc((100% / ${props => props.number}) * ((${props => props.from} - ${props => props.min} - 1)));
    border-radius: 13px;
`
