import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Paragraph, TemperatureParagraph, LocationParagraph, LifecycleParagraph, RinvasoParagraph, SpecieParagraph } from "./Paragraphs"
import * as styles from "../styles/plantContent.module.css"

export default function PlantContent({ plant }) {
    return (
        <>
            {plant.isAvailable &&
                <div className={styles.contentContainer}>
                    <h1 className={styles.title}>{plant.name}</h1>

                    {plant.image.childImageSharp &&
                        <GatsbyImage image={plant.image.childImageSharp.gatsbyImageData} className={styles.image} alt={plant.name} />
                    }

                    {plant.classificazione &&
                        <Paragraph id='classificazione' title='Classificazione' text={plant.classificazione} />
                    }

                    {plant.caratteristiche &&
                        <Paragraph id='caratteristiche' title='Caratteristiche' text={plant.caratteristiche} />
                    }

                    {plant.temperatura &&
                        <TemperatureParagraph id='temperatura' title='Temperatura' plant={plant} />
                    }

                    {plant.posizione &&
                        <LocationParagraph id='posizione' title='Posizione' plant={plant} />
                    }

                    {plant.ciclo &&
                        <LifecycleParagraph id='ciclo' title='Ciclo vitale' plant={plant} />
                    }

                    {plant.fioritura &&
                        <Paragraph id='fioritura' title='Fioritura' text={plant.fioritura} />
                    }

                    {plant.foglie &&
                        <Paragraph id='foglie' title='Foglie' text={plant.foglie} />
                    }

                    {plant.annaffiatura &&
                        <Paragraph id='annaffiatura' title='Annaffiatura' text={plant.annaffiatura} />
                    }

                    {plant.nebulizzazione &&
                        <Paragraph id='nebulizzazione' title='Nebulizzazione' text={plant.nebulizzazione} />
                    }

                    {plant.rinvaso &&
                        <RinvasoParagraph id='rinvaso' title='Rinvaso' plant={plant} />
                    }

                    {plant.terreno &&
                        <Paragraph id='terreno' title='Terreno' text={plant.terreno} />
                    }

                    {plant.concimazione &&
                        <Paragraph id='concimazione' title='Concimazione' text={plant.concimazione} />
                    }

                    {plant.potatura &&
                        <Paragraph id='potatura' title='Potatura' text={plant.potatura} />
                    }

                    {plant.moltiplicazione &&
                        <Paragraph id='moltiplicazione' title='Moltiplicazione' text={plant.moltiplicazione} />
                    }

                    {plant.malattie &&
                        <Paragraph id='malattie' title='Malattie' text={plant.malattie} />
                    }

                    {plant.parassiti &&
                        <Paragraph id='parassiti' title='Parassiti' text={plant.parassiti} />
                    }

                    {plant.specie &&
                        <SpecieParagraph id='specie' title='Specie' plants={plant.specie} />
                    }
                </div>
            }

            {!plant.isAvailable &&
                <div className={styles.emptyContainer}>
                    <h2 className={styles.centeredText}>Questa pianta non è ancora disponibile</h2>
                    <p className={styles.centeredText}>Inserisci la tua email per ricevere una notifica quando nuove piante saranno aggiunte.</p>

                    {/* <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7_dtp.css" rel="stylesheet" type="text/css">
                        <style type="text/css">

                        </style> */}
                    <div id="mc_embed_signup">
                        <form action="https://gmail.us9.list-manage.com/subscribe/post?u=75887b43a66b494fdac1b5210&amp;id=41c5249708" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                            <div className={styles.formContainer}>
                                <div className="mc-field-group">
                                    <input type="email" defaultValue="" name="EMAIL" placeholder="Email" id="mce-EMAIL" autoCorrect="off" required />
                                </div>
                                <div hidden={true}>
                                    <input type="hidden" name="tags" defaultValue="12457167" />
                                </div>
                                <div id="mce-responses" className="clear foot">
                                    <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
                                    <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
                                </div>
                                {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                                <div style={{ position: "absolute", left: "-5000px", ariaHidden: true }}>
                                    <input type="text" name="b_75887b43a66b494fdac1b5210_41c5249708" tabIndex="-1" defaultValue="" />
                                </div>
                                <div className="optionalParent">
                                    <div className="clear foot">
                                        <input type="submit" value="Invia" name="subscribe" id="mc-embedded-subscribe" className="button" />
                                        {/* <p className="brandingLogo">
                                                    <a href="http://eepurl.com/h1NEej" title="Mailchimp - email marketing made easy and fun">
                                                        <img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg" />
                                                    </a>
                                                </p> */}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
                    {/* <script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script> */}
                    {/* </link> */}
                </div>
            }
        </>
    )
}