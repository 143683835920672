import React, { useState } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import * as styles from "../styles/sidebar.module.css"
import { useLocation } from "@reach/router"
import { Container } from "../styles/sidebarElements"
import OutsideAlerter from "./OutsideAlerter"

export default function Sidebar({ plant }) {

  const location = useLocation()

  const [isOpen, setIsOpen] = useState(false);

  function selectedItem(to) {
    scrollTo('#' + to, 'center')
    setIsOpen(false)
  }

  const Item = ({ to, text }) => {
    return (
      <li className={styles.navItem}>
        <a className={styles.navLinks} onClick={() => selectedItem(to)}>{text}</a>
      </li>
    )
  }

  return (
    <>
      <label className={styles.indexIcon} onClick={() => setIsOpen(true)}>
        <img src='/index.png' />
      </label>

      <OutsideAlerter action={() => setIsOpen(false)}>
        <Container isOpen={isOpen}>
          <nav className={styles.navContainer}>
            <p className={styles.indexText}>Indice</p>

            {plant.classificazione &&
              <Item to='classificazione' text='Classificazione' />
            }

            {plant.caratteristiche &&
              <Item to='caratteristiche' text='Caratteristiche' />
            }

            {plant.temperatura &&
              <Item to='temperatura' text='Temperatura' />
            }

            {plant.posizione &&
              <Item to='posizione' text='Posizione' />
            }

            {plant.ciclo &&
              <Item to='ciclo' text='Ciclo vitale' />
            }

            {plant.fioritura &&
              <Item to='fioritura' text='Fioritura' />
            }

            {plant.foglie &&
              <Item to='foglie' text='Foglie' />
            }

            {plant.annaffiatura &&
              <Item to='annaffiatura' text='Annaffiatura' />
            }

            {plant.nebulizzazione &&
              <Item to='nebulizzazione' text='Nebulizzazione' />
            }

            {plant.rinvaso &&
              <Item to='rinvaso' text='Rinvaso' />
            }

            {plant.terreno &&
              <Item to='terreno' text='Terreno' />
            }

            {plant.concimazione &&
              <Item to='concimazione' text='Concimazione' />
            }

            {plant.potatura &&
              <Item to='potatura' text='Potatura' />
            }

            {plant.moltiplicazione &&
              <Item to='moltiplicazione' text='Moltiplicazione' />
            }

            {plant.malattie &&
              <Item to='malattie' text='Malattie' />
            }

            {plant.parassiti &&
              <Item to='parassiti' text='Parassiti' />
            }

            {plant.specie &&
              <Item to='specie' text='Specie' />
            }

            <div className="padTop hstack">
              <a className="twitter-share-button smallIcon" target="_blank" href={`https://twitter.com/intent/tweet?text=${plant.descrizione}&url=${location.href}&hashtags=plantcare,${plant.name}`}
                data-size="large">
                <img className="smallIcon" src='/twitter.png' />
              </a>

              <div className="fb-share-button" data-href="https://developers.facebook.com/docs/plugins/" data-layout="button" data-size="small">
                <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`} className="fb-xfbml-parse-ignore smallIcon">
                  <img className="smallIcon" src='/facebook.png' />
                </a>
              </div>
            </div>
          </nav>
        </Container>
      </OutsideAlerter>
    </>
  )
}
