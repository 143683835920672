import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "../styles/plantContent.module.css"
import { Dot, SliderLine } from "../styles/plantContentElements"
import { Remarkable } from 'remarkable';
var md = new Remarkable('commonmark');

function uuidv4() {
    var u = '', i = 0;
    while (i++ < 36) {
        var c = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'[i - 1], r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
        u += (c === '-' || c === '4') ? c : v.toString(16)
    }
    return u;
}

export const MonthsSlider = ({ title, from, to }) => {

    var months = ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"];

    return (
        <div className={styles.sliderGeneralContainer}>
            <p className={styles.sliderText}>{title}</p>

            <div className={styles.sliderContainer}>
                <div className={styles.sliderBackground}>
                    {Array(months.length).fill(1).map(() =>
                        <Dot number={12} key={uuidv4()} />
                    )}
                </div>

                <SliderLine number={12} min={0} from={from} to={to}>
                    <p className={styles.monthsText}>{months[from - 1]} - {months[to - 1]}</p>
                </SliderLine>
            </div>
        </div>
    )
}

export const TemperatureSlider = ({ title, from, to }) => {
    return (
        <div className={styles.sliderGeneralContainer}>
            <p className={styles.sliderText}>{title}</p>

            <div className={styles.sliderContainer}>
                <div className={styles.sliderBackground}>
                    {Array(4).fill(1).map(() =>
                        <Dot number={4} key={uuidv4()} />
                    )}
                </div>

                <SliderLine number={41} min={-1} from={from} to={to}>
                    <p className={styles.monthsText}>{from}° - {to}°</p>
                </SliderLine>
            </div>
        </div>
    )
}


/* Paragrafi */

export const BaseParagraph = ({ id, title, children }) => {
    return (
        <div className={styles.horizontalContainer} id={id}>
            <div className={styles.verticalLine} />

            <div className={styles.subtitleContainer}>
                <h2 className={styles.subtitle}>{title}</h2>
                {children}
            </div>
        </div>
    )
}

export const Paragraph = ({ id, title, text }) => {
    return (
        <BaseParagraph id={id} title={title}>
            <p className={styles.text} dangerouslySetInnerHTML={{ __html: md.render(text) }} />
        </BaseParagraph>
    )
}

export const TemperatureParagraph = ({ id, title, plant }) => {
    return (
        <BaseParagraph id={id} title={title}>
            <TemperatureSlider title={'Estate'} from={plant.temperatura.estate_min} to={plant.temperatura.estate_max} />
            <TemperatureSlider title={'Inverno'} from={plant.temperatura.inverno_min} to={plant.temperatura.inverno_max} />
        </BaseParagraph>
    )
}

export const LocationParagraph = ({ id, title, plant }) => {
    return (
        <BaseParagraph id={id} title={title}>
            <MonthsSlider title={'Interno'} from={plant.posizione.interno_min} to={plant.posizione.interno_max} />
            <MonthsSlider title={'Esterno'} from={plant.posizione.esterno_min} to={plant.posizione.esterno_max} />
            <p className={styles.text} dangerouslySetInnerHTML={{ __html: md.render(plant.posizione.testo) }} />
        </BaseParagraph>
    )
}

export const LifecycleParagraph = ({ id, title, plant }) => {
    return (
        <BaseParagraph id={id} title={title}>
            <MonthsSlider title={'Vegetazione'} from={plant.ciclo.vegetazione_min} to={plant.ciclo.vegetazione_max} />
            <MonthsSlider title={'Dormienza'} from={plant.ciclo.dormienza_min} to={plant.ciclo.dormienza_max} />
        </BaseParagraph>
    )
}

export const RinvasoParagraph = ({ id, title, plant }) => {
    return (
        <BaseParagraph id={id} title={title}>
            <MonthsSlider title={''} from={plant.rinvaso.min} to={plant.rinvaso.max} />
            <p className={styles.text} dangerouslySetInnerHTML={{ __html: md.render(plant.rinvaso.testo) }} />
        </BaseParagraph>
    )
}

export const SpecieParagraph = ({ id, title, plants }) => {
    return (
        <BaseParagraph id={id} title={title}>
            <div className={styles.specieGeneralContainer}>
                {plants.map(plant =>
                    <div className={styles.specieContainer} key={uuidv4()} >
                        {plant.immagine &&
                            <GatsbyImage image={plant.immagine.childImageSharp.gatsbyImageData} className={styles.specieImage} alt={plant.nome} />
                        }

                        <h3 className={`${styles["subtitle"]} ${styles["marginLeft"]}`}>{plant.nome}</h3>
                        <p className={`${styles["text"]} ${styles["marginLeft"]}`} dangerouslySetInnerHTML={{ __html: md.render(plant.descrizione) }} />
                    </div>
                )}
            </div>
        </BaseParagraph>
    )
}