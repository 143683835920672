// extracted by mini-css-extract-plugin
export var centeredText = "plantContent-module--centeredText--Yh+Vt";
export var contentContainer = "plantContent-module--contentContainer--jSjUK";
export var emptyContainer = "plantContent-module--emptyContainer--ICV7j";
export var formContainer = "plantContent-module--formContainer--WcFqC";
export var horizontalContainer = "plantContent-module--horizontalContainer--8phQ4";
export var icon = "plantContent-module--icon--UVO6p";
export var image = "plantContent-module--image--iusIa";
export var marginLeft = "plantContent-module--marginLeft--eDq7W";
export var monthsText = "plantContent-module--monthsText--mcafb";
export var sliderBackground = "plantContent-module--sliderBackground--lU2+L";
export var sliderContainer = "plantContent-module--sliderContainer--jwhPF";
export var sliderGeneralContainer = "plantContent-module--sliderGeneralContainer--3xxrF";
export var sliderText = "plantContent-module--sliderText--CSXTg";
export var specieContainer = "plantContent-module--specieContainer--ESOqh";
export var specieGeneralContainer = "plantContent-module--specieGeneralContainer--wJs5c";
export var specieImage = "plantContent-module--specieImage--b0X3z";
export var subtitle = "plantContent-module--subtitle--QL84Y";
export var subtitleContainer = "plantContent-module--subtitleContainer--Ju4tY";
export var text = "plantContent-module--text--MkNRI";
export var title = "plantContent-module--title--8v3pi";
export var verticalLine = "plantContent-module--verticalLine--BahTL";