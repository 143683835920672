import styled from "styled-components"

export const Container = styled.aside`
    display: flex;
    position: fixed;
    width: 20%;
    height: calc(100vh - 80px);
    top: 80px;
    left: 0px;
    z-index: 10;
    background-color: white;

    @media screen and (max-width: 900px) {
        width: auto;
        transform: translatex(${({ isOpen }) => (isOpen ? '0' : '-100%')});
        transition: transform 0.4s ease-in-out;
    }
`