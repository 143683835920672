import React, { useState, useEffect } from "react"
import "../styles/global.css"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Sidebar from "../components/Sidebar"
import Alphabet from "../components/Alphabet"
import PlantContent from "../components/PlantContent"
// import Popup from "../components/Popup"
// import OutsideAlerter from "../components/OutsideAlerter"
import Seo from "../components/Seo"
import SearchBox from "../components/SearchBox"

export default function PlantDetails({ data }) {

  const plant = data.plantsDataJson

  // const [isOpen, setIsOpen] = useState(false);
  // const [letter, setLetter] = useState('');
  // const [y, setY] = useState(0);

  // function togglePopup(letter) {
  //   if (!isOpen) {
  //     var offset = offsets[letter] + 90
  //     const windowHeight = window.innerHeight
  //     const maxOffset = offsets[letter] + 530

  //     if (windowHeight < maxOffset) {
  //       offset -= maxOffset - windowHeight
  //     }

  //     setY(offset);
  //   }

  //   setLetter(letter);
  //   setIsOpen(!isOpen);
  // }

  // const [offsets, setOffsets] = useState({ '': 0 })

  // useEffect(() => {
  //   const offs = { '': 0 }

  //   const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

  //   letters.forEach((letter, i) =>
  //     offs[letter] = document.getElementById(letter).offsetTop
  //   )

  //   setOffsets(offs)
  // }, []);

  return (
    <>
      <Seo title={plant.title} description={plant.descrizione} image={plant.image.childImageSharp.gatsbyImageData.images.fallback.src} article={true} plant={plant} />

      <Layout plant={plant}>
        {plant.isAvailable &&
          <Sidebar plant={plant} />
        }

        {/* <Alphabet togglePopup={togglePopup} showSearch={setSearchIsOpen} /> */}
        <PlantContent plant={plant} />

        {/* {isOpen &&
          <OutsideAlerter action={togglePopup} >
            <Popup letter={letter} number={y} isOpen={isOpen} />
          </OutsideAlerter>
        } */}
      </Layout>
    </>
  )
}

export const query = graphql`
  query Plant($slug: String) {
    plantsDataJson(slug: {eq: $slug}) {
      name
      slug
      plantId
      isAvailable
      title
      descrizione
      annaffiatura
      caratteristiche
      ciclo {
        dormienza_max
        dormienza_min
        vegetazione_max
        vegetazione_min
      }
      classificazione
      concimazione
      fioritura
      image {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      moltiplicazione
      navInfo {
        annaffiatura
        luce
        posizione
        rinvaso
        temperatura
      }
      nebulizzazione
      parassiti
      malattie
      posizione {
        interno_min
        testo
        interno_max
        esterno_min
        esterno_max
      }
      potatura
      rinvaso {
        max
        min
        testo
      }
      specie {
        descrizione
        nome
        immagine {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      terreno
      temperatura {
        inverno_max
        inverno_min
        estate_min
        estate_max
      }
    }
  }  
`